import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const schema = z.object({
    email: z.string({
        'message':'Email field is Required'
    }).email('It must be a valid email address'),
    first_name: z.string({'message':'First Name field is Required'}),
    last_name: z.string({'message':'Last Name field is Required'}),
})

export const myProfileSchema = toFormikValidationSchema(schema)
