import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Box, CircularProgress, Typography } from '@mui/joy';
import { useFormik } from 'formik';
import { workerSchema } from '@/src/pages/secure/profile/modals/create-worker-modal/schema';
import { TextInput } from '@/components/text-input';
import { colors } from '@/utilities/colors';
import { SelectInput } from '@/components/select-input';
import PhoneInput, { getCountries } from 'react-phone-number-input'
import { useAdminController } from '@/services/use-admin';

export function CreateWorkerModal() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const allowedCountries = ['US', 'CA', 'GB', 'AU', 'NG', 'GH'];
  const countries = getCountries().filter(country => allowedCountries.includes(country));


  const adminController = useAdminController();

  const formik = useFormik({
    onSubmit: async (values) => {
      setLoading(true);
      await adminController.onCreateWorker(values).then(() => {
        setLoading(false)
        formik.resetForm();
      }).catch(() => {
        setLoading(false)
      })
    },
    initialValues: {
      firstName: '',
      lastName: '',
      gender: '',
      phoneNumber: '',
      email: '',
    },
    validationSchema: workerSchema,
  })

  React.useEffect(() => {
    adminController.setErrorMessage('')
    adminController.setSuccessMessage('')
  }, [formik.values])

  return (
    <React.Fragment>
      <Button
        sx={{ '&:focus': { outline: 'none' } }}
        onClick={() => setOpen(true)}
      >
        Create Worker
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} >
        <ModalDialog sx={{ minWidth: { xs: '100%', sm: '80%', md: '30rem', maxHeight: '100%', overflowY: 'auto' } }}>
          <Typography level='body-sm' py={1}>
            Enter worker details
          </Typography>
          <Box>
            <TextInput
              label="First Name"
              placeholder="Enter First Name"
              sxLabel={{ py: 1.5, color: colors.textColor, }}
              size={'lg'}
              errorText={
                formik.touched.firstName && formik.errors?.firstName
                  ? formik.errors.firstName
                  : undefined
              }
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <TextInput
              label="Last Name"
              placeholder="Enter Last Name"
              sxLabel={{ py: 1.5, color: colors.textColor, }}
              size={'lg'}
              errorText={
                formik.touched.lastName && formik.errors?.lastName
                  ? formik.errors.lastName
                  : undefined
              }
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <SelectInput
              options={[
                { value: 1, text: 'Male' },
                { value: 2, text: 'Female' },
              ]}
              placeholder="Select Gender"
              name="gender"
              label='Gender'
              sxLabel={{ py: 1.5, color: colors.textColor, }}
              errorText={
                formik.touched.gender && formik.errors?.gender
                  ? formik.errors.gender
                  : undefined
              }
              value={formik.values.gender}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Box>
              <Typography level={'body-sm'} sx={{ py: 1.5, color: colors.textColor }}>
                Phone Number
              </Typography>
              <PhoneInput
                countryCallingCodeEditable={false}
                international
                countries={countries}
                defaultCountry='CA'
                placeholder="Enter phone number"
                value={formik.values.phoneNumber}
                onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                style={{
                  minHeight: '2.5rem',
                  border: formik.touched.phoneNumber && formik.errors?.phoneNumber
                    ? `1px solid ${colors.red}` : `1px solid ${colors.textColor}`,
                }}
              />
              <Typography level={'body-xs'} sx={{ color: colors.red, mb: 1 }}>
                {formik.touched.phoneNumber && formik.errors?.phoneNumber ? formik.errors.phoneNumber : ''}
              </Typography>
            </Box>
            <TextInput
              label="Email"
              placeholder="nuru@gmail.com"
              sxLabel={{ py: 1.5, color: colors.textColor, }}
              size={'lg'}
              errorText={
                formik.touched.email && formik.errors?.email
                  ? formik.errors.email
                  : undefined
              }
              type="email"
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={loading}
              sx={{ minWidth: '100%', mx: 'auto', '&:focus': { outline: 'none' } }}
              onClick={() => formik.handleSubmit()}>
              {loading ? <CircularProgress /> : 'Create'}
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}