import { PropsWithChildren } from 'react'
import { OverlayView, OverlayViewF, InfoWindow } from '@react-google-maps/api'
import { motion } from 'framer-motion'
import { colors } from '@/utilities/colors.ts'
import { Box, Typography } from '@mui/joy'
import StarPurple500Icon from '@mui/icons-material/StarPurple500';

interface ICheckinMarkerProps {
    cords: {
        lng: number
        lat: number
    }
    status: string
    index: number
    onClick: () => void
    isSelected: boolean
}

export function CheckinMarker(props: PropsWithChildren<ICheckinMarkerProps>) {
    return (
        <OverlayViewF
            position={props.cords}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            // when users select it, move the marker to the foreground
            zIndex={props.isSelected ? 99 : 0}
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    type: 'spring',
                    stiffness: 400,
                    damping: 20,
                    delay: Math.random() * 0.3,
                }}
                whileHover={{
                    zIndex: 9999,
                }}
                style={{
                    border: `1px solid ${colors.white}`,
                }}
                onClick={props.onClick}
            >
                <Box
                    sx={{
                        backgroundColor: props.status === 'online'
                            ?  colors.success
                            : colors.red,
                        color: colors.white,
                        border: 0,
                        padding: 2,
                        borderRadius: "50%",
                        cursor: 'pointer',
                        width: '6px',
                        height: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {
                        props.isSelected ? <StarPurple500Icon sx={{color:'yellow'}} /> : <Typography sx={{ fontSize: 15, fontWeight: 600, color: colors.white }}>
                            {props.index}
                        </Typography>
                    }

                </Box>
                {/* {props.isSelected ? (
                    <InfoWindow onCloseClick={() => { }}>
                        fill property
                    </InfoWindow>
                ): ''} */}
            </motion.div>
        </OverlayViewF>
    )
}
