import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from '@/utilities/react-query-client.ts'
import App from './App.tsx'
import './index.css'
import './styles.css'

import { CssVarsProvider } from '@mui/joy/styles'
import { theme } from '@/utilities/theme.ts'
import { Box } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';


// Create a theme instance
const muiTheme = createTheme({
 
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <CssVarsProvider theme={theme}>
          <BrowserRouter>
            <Box>
              <App />
            </Box>
          </BrowserRouter>
        </CssVarsProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
)
