import { useEffect, useState } from "react";
import { colors } from "@/utilities/colors";
import { Box, Sheet, Table, Button, CircularProgress, Typography, ListItemContent, ListItem, List } from "@mui/joy";
import { CreateAdminModal } from "@/src/pages/secure/profile/modals/create-admin-modal";
import { useAdminController } from "@/services/use-admin";
import { User } from "@/services/use-admin/schema";
import { isEmpty } from "lodash";
import { Tooltip } from '@/components/tooltip';
import { SendMessageAlert } from "@/components/send-message";
import { ViewDetails } from "@/src/pages/secure/profile/modals/view-details";


export function AdminTableInfo() {
    const { onGetAdmins, isPending, errorMessage } = useAdminController();
    const [admins, setAdmins] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchAdmins = async (page: number) => {
        try {
            const response = await onGetAdmins(page);
            setAdmins(response.data.data);
            setTotalPages(Math.ceil(response.data.total / response.data.limit));
        } catch (error) {
            console.error("Failed to fetch admins:", error);
        }
    };



    useEffect(() => {
        fetchAdmins(currentPage);
    }, [currentPage]);



    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <Box sx={{ mx: 5 }}>
            <Box sx={{ width: '100%', mb: 2, display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
                <CreateAdminModal />
            </Box>
            <Sheet>
                {
                    isPending ?
                        <Box sx={{ width: '100%', height: '10rem', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box> :
                        errorMessage ? <Typography sx={{ color: colors.red, py: 5, px: 3, textAlign: 'center' }}>{errorMessage}</Typography> :

                            <Table aria-label="table with sticky header" stickyHeader hoverRow>
                                <thead>
                                    <tr style={{ backgroundColor: colors.primary }}>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '2.5rem' }}>S/N</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '6.5rem' }}>First Name</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '6.5rem' }}>Last Name</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '3.5rem' }}>Role</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '6.5rem' }}>Phone Number</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '6.5rem' }}>Email</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '3.5rem' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {admins.map((admin: User, index) => (
                                        <tr key={admin.id}>
                                            <td>{index + 1 + (currentPage - 1) * 10}</td>
                                            <td>{admin.firstName}</td>
                                            <td>{admin.lastName}</td>
                                            <td>{admin.roleId}</td>
                                            <td>{admin.phoneNumber}</td>
                                            <td>{admin.email}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <Tooltip id={admin.id}>
                                                    <Box sx={{ border: 1, p: 1, bgcolor: colors.white }}>
                                                        <List component="nav" aria-label="main mailbox folders">
                                                            <ListItem>
                                                                <ListItemContent>
                                                                    <ViewDetails user={admin} title="View Admin Details" />
                                                                </ListItemContent>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemContent>
                                                                    <SendMessageAlert
                                                                        id={admin.id}
                                                                        firstName={admin.firstName}
                                                                        lastName={admin.lastName}
                                                                    />
                                                                </ListItemContent>
                                                            </ListItem>
                                                        </List>
                                                    </Box>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                }

            </Sheet>
            {
                !isPending && isEmpty(errorMessage) &&
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        Previous
                    </Button>
                    <Box sx={{ padding: '0 1rem', alignSelf: 'center' }}>
                        Page {currentPage} of {totalPages}
                    </Box>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        Next
                    </Button>
                </Box>
            }
        </Box>
    );
}
