import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const schema = z.object({
    emergencyNumber: z.string().optional(),
    defaulterReportingNumber: z.string().optional(),
    alertTime: z.number().optional(),
    moveAroundTime: z.number().optional(),
    messageNotificationNumber: z.string().optional(),
    messageNotificationEmail: z.string().email({message:'Notification Email Must be A valid Email'}).optional(),
})

export const settingsSchema = toFormikValidationSchema(schema)
