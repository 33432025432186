import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { app } from '@/src/config/app';
import ProfileIcon from '@/src/assets/svgs/dashboard/profile.svg?react';
import ProfileIconBlue from '@/src/assets/svgs/dashboard/profile_blue.svg?react';
import MenuIcon from '@/src/assets/svgs/dashboard/menu.svg?react';
import DashboardIcon from '@/src/assets/svgs/dashboard/dashboard.svg?react';
import DashboardIconBlue from '@/src/assets/svgs/dashboard/dashboard_blue.svg?react';
import SettingsIcon from '@/src/assets/svgs/dashboard/settings.svg?react';
import { Link, useLocation } from 'react-router-dom';
import { colors } from '@/utilities/colors';
import {useStore} from '@/src/store/user';
import { useAuthUserController } from '@/services/use-get-auth-user';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export default function Sidebar() {
  const { pathname } = useLocation();
  const {userInfo}= useStore()
  const authUserController = useAuthUserController()


  function logout(){
    authUserController.logout();
  }


  return (
    <Sheet
      className="Sidebar"
      sx={{
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        //width: '200px',
        //top: 0,
        px: 2,
        //left: 0,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: colors.lightGray
      }}
    >
      {/* <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      /> */}
      {/* <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: colors.secondary,
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      /> */}
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography level="title-lg">{app.name}</Typography>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton>
              <MenuIcon style={{ width: '22px', height: '22px' }} />
              <ListItemContent>
                <Typography level="title-sm" fontWeight={600}>Menu</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={pathname === '/dashboard'}
              role="menuitem"
              component={Link}
              to='/dashboard'
            >
              {
                pathname === '/dashboard' ?
                <DashboardIconBlue style={{ width: '22px', height: '22px' }} />
                :
                <DashboardIcon style={{ width: '22px', height: '22px'}} />
              }
              <ListItemContent>
                <Typography level="title-sm" sx={{ color: pathname === '/dashboard' ? colors.primary : '' }}>Dashboard</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={pathname === '/dashboard/profile' || pathname === '/dashboard/my-profile'}
              role="menuitem"
              component={Link}
              to='/dashboard/profile'
            >
               {
                pathname === '/dashboard/profile' || pathname === '/dashboard/my-profile' ?
                <ProfileIconBlue style={{ width: '22px', height: '22px' }} />
                :
                <ProfileIcon style={{ width: '22px', height: '22px'}} />
              }
              <ListItemContent>
                <Typography level="title-sm" sx={{ color: pathname === '/dashboard/profile' || pathname === '/dashboard/my-profile' ? colors.primary : '' }}>Profile</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={pathname === '/dashboard/checkin-history'}
              role="menuitem"
              component={Link}
              to='/dashboard/checkin-history'
            >
               {
                pathname === '/dashboard/profile' ?
                <MyLocationIcon style={{ width: '22px', height: '22px' }} />
                :
                <MyLocationIcon style={{ width: '22px', height: '22px'}} />
              }
              <ListItemContent>
                <Typography level="title-sm" sx={{ color: pathname === '/dashboard/checkin-history' ? colors.primary : '' }}>Check In History</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={pathname === '/dashboard/settings'}
              role="menuitem"
              component={Link}
              to='/dashboard/settings'
            >
              <SettingsIcon style={{ width: '22px', height: '22px', color: pathname === '/dashboard/settings' ? colors.primary : '' }} />
              <ListItemContent>
                <Typography level="title-sm" sx={{ color: pathname === '/dashboard/settings' ? colors.primary : '' }}>Settings</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

        </List>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pb: 5, px: 2 }}>
        <Link to='/dashboard/my-profile'>
        <Box display='flex' sx={{ flexWrap: 'wrap', alignItems: 'center', gap: '0.5rem' }}>
          <Avatar
            variant="outlined"
            size="sm"
            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
          />
          <Typography>{userInfo?.firstName || ''}</Typography>
        </Box>
        </Link>
        <Button
          sx={{ width: '100%', mt: '1.5rem', fontWeight:400 }}
          onClick={() => logout()}
        >Log Out</Button>
      </Box>
    </Sheet>
  );
}
