import * as React from 'react';
import Button from '@mui/joy/Button';
import { Box } from '@mui/joy';
import { useFormik } from 'formik';
import { settingsSchema } from '@/src/pages/secure/settings/components/schema';
import { TextInput } from '@/components/text-input';
import { colors } from '@/utilities/colors';
import { useAdminController } from '@/services/use-admin';

export function SettingsForm() {
  const { getConfigSettings, isPending, onUpdateSettings} = useAdminController();

  const formik = useFormik({
    onSubmit: async (values) => {
      await onUpdateSettings(values);
    },
    initialValues: {
      emergencyNumber: '',
      defaultersReportingNumber: '',
      alertTime: 0,
      moveAroundTime: 0,
      messageNotificationNumber: '',
      messageNotificationEmail: '',
    },
    validationSchema: settingsSchema,
  });

  React.useEffect(() => {
    const fetchConfigSettings = async () => {
      try {
        const response = await getConfigSettings();
        if (response && response.data) {
          formik.setValues({
            emergencyNumber: response.data.emergencyNumber || '',
            defaultersReportingNumber: response.data.defaulterReportingNumber || '',
            alertTime: response.data.alertTime || 0,
            moveAroundTime: response.data.moveAroundTime || 0,
            messageNotificationNumber: response.data.messageNotificationNumber || '',
            messageNotificationEmail: response.data.messageNotificationEmail || '',
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchConfigSettings();
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ width: { xs: '100%', sm: '80%', md: '30rem', maxHeight: '100%', overflowY: 'auto', margin: '0 auto' } }}>
        <TextInput
          disabled={isPending}
          label="Emergency Number"
          placeholder={`${isPending ? '...' : 'placeholder'}`}
          sxLabel={{ py: 1.5, color: colors.textColor }}
          size="lg"
          errorText={
            formik.touched.emergencyNumber && formik.errors?.emergencyNumber
              ? formik.errors.emergencyNumber
              : undefined
          }
          type="text"
          name="emergencyNumber"
          value={formik.values.emergencyNumber}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextInput
        disabled={isPending}
          label="Defaulters Reporting Number"
          placeholder={`${isPending ? '...' : 'placeholder'}`}
          sxLabel={{ py: 1.5, color: colors.textColor }}
          size="lg"
          errorText={
            formik.touched.defaultersReportingNumber && formik.errors?.defaultersReportingNumber
              ? formik.errors.defaultersReportingNumber
              : undefined
          }
          type="text"
          name="defaultersReportingNumber"
          value={formik.values.defaultersReportingNumber}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextInput
          label="Alert Time"
          disabled={isPending}
          placeholder={`${isPending ? '...' : 'placeholder'}`}
          sxLabel={{ py: 1.5, color: colors.textColor }}
          size="lg"
          errorText={
            formik.touched.alertTime && formik.errors?.alertTime
              ? formik.errors.alertTime
              : undefined
          }
          type="number"
          name="alertTime"
          value={formik.values.alertTime}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextInput
          label="Move Around Time"
          disabled={isPending}
          placeholder={`${isPending ? '...' : 'placeholder'}`}
          sxLabel={{ py: 1.5, color: colors.textColor }}
          size="lg"
          errorText={
            formik.touched.moveAroundTime && formik.errors?.moveAroundTime
              ? formik.errors.moveAroundTime
              : undefined
          }
          type="number"
          name="moveAroundTime"
          value={formik.values.moveAroundTime}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextInput
          label="Message Notification Number"
          disabled={isPending}
          placeholder={`${isPending ? '...' : 'placeholder'}`}
          sxLabel={{ py: 1.5, color: colors.textColor }}
          size="lg"
          errorText={
            formik.touched.messageNotificationNumber && formik.errors?.messageNotificationNumber
              ? formik.errors.messageNotificationNumber
              : undefined
          }
          type="text"
          name="messageNotificationNumber"
          value={formik.values.messageNotificationNumber}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <TextInput
          label="Message Notification e-Mail"
          disabled={isPending}
          placeholder={`${isPending ? '...' : 'placeholder'}`}
          sxLabel={{ py: 1.5, color: colors.textColor }}
          size="lg"
          errorText={
            formik.touched.messageNotificationEmail && formik.errors?.messageNotificationEmail
              ? formik.errors.messageNotificationEmail
              : undefined
          }
          type="text"
          name="messageNotificationEmail"
          value={formik.values.messageNotificationEmail}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        <Box sx={{ mt: '3rem' }}>
          <Button sx={{ width: '100%' }} onClick={() => formik.handleSubmit()}>Save</Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
