import { Avatar, Box, Typography } from "@mui/joy";
import React from "react";
import { Header } from "@/src/pages/secure/components/Header";
import { MyProfileForm } from "@/src/pages/secure/profile/my-profile/components/MyProfileForm";
import { useStore } from "@/src/store/user";
export function MyProfile() {
const {userInfo} = useStore()

    return (
        <React.Fragment>
            <Header title='Profile' />
            <Box sx={{px: 10, display: 'flex', minHeight: '100dvh', gap: '2rem', alignItems:'flex-start' }}>
                <Box sx={{ width: '15rem', display: 'flex', justifyContent: 'center', mt:3 }}>
                    <Box display='flex' sx={{ display:'grid', gap:2 }}>
                        <Avatar
                            variant="outlined"
                            size="lg"
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                        />
                        <Typography>{userInfo?.firstName || ''}{' '+userInfo?.lastName || ''}</Typography>
                    </Box>
                </Box>
                <Box flexGrow={1}>
                    <MyProfileForm />
                </Box>
            </Box>
        </React.Fragment>
    )
}