import { Routes, Route } from 'react-router-dom'
import './App.css'

import { ErrorPage } from '@/src/pages/errors/error-page'
import { Login } from '@/src/pages/auth/login'
import { ForgotPassword } from '@/src/pages/auth/forgot-password'
import { AuthUserProvider } from '@/services/use-get-auth-user'
import { AdminUserProvider } from '@/services/use-admin'
import { SecureLayout } from '@/src/pages/secure/secure-layout'
import { Dashboard } from '@/src/pages/secure/dashboard'
import { Profile } from '@/src/pages/secure/profile'
import { MyProfile } from '@/src/pages/secure/profile/my-profile'
import { Settings } from '@/src/pages/secure/settings'
import { ChangePassword } from '@/src/pages/auth/change-password'
import { CheckInHistory } from '@/src/pages/secure/checkin-history'
/*import { ToastProvider } from '@/services/use-toast'
import { AccountSettings } from '@/src/pages/secure/account-settings'
import { SignUp } from '@/src/pages/auth/sign-up'
import { RequestPasswordReset } from '@/src/pages/auth/request-password-reset'
import { ResetPassword } from '@/src/pages/auth/reset-password'
import { AuthLayout } from '@/src/pages/auth/auth-layout'
 */
function App() {

  return (
    <AuthUserProvider>
    <AdminUserProvider>
      <Routes>
        <Route
          path={'/'}
          element={<SecureLayout />}
        >
          <Route index element={<Dashboard />} />
          </Route>
        <Route
          path={'/login'}
          element={<Login />}
        />
        <Route
          path={'/forgot-password'}
          element={<ForgotPassword />}
        />
        <Route
          path={'/change-password'}
          element={<ChangePassword />}
        />
        <Route
          path={'dashboard'}
          element={<SecureLayout />}
        >
          <Route path='' element={<Dashboard />} />
          <Route path='profile' element={<Profile />} />
          <Route path='checkin-history' element={<CheckInHistory />} />
          <Route path='settings' element={<Settings />} />
          <Route path='my-profile' element={<MyProfile />} />
          <Route index element={<Dashboard />} />
        </Route>

        <Route path={'*'} element={<ErrorPage />} />
      </Routes>
    </AdminUserProvider>
    </AuthUserProvider>
  )
}

export default App
