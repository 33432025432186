import React from 'react';
import { Grid } from '@mui/material';
import { CheckinList } from '@/src/pages/secure/dashboard/components/CheckinList';


export function Dashboard() {

  return (
    <React.Fragment>
      <Grid>
        <Grid item xs={12}>
          <CheckinList />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
