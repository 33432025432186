import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Popper } from '@mui/base/Popper';
import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/joy';


export function Tooltip(props: { id: string | number|undefined, children: React.ReactNode }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <div>
          <IconButton
            aria-describedby={id}
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          >
            <MoreVert />
          </IconButton>
          <Popper id={id} open={open} anchorEl={anchorEl}>
              {props.children}
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
}
