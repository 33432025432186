import React from "react";
import { TextInput } from "@/components/text-input";
import { colors } from "@/utilities/colors";
import { Box, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, Navigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/joy";
import { useFormik } from 'formik'
import { loginSchema } from "@/src/pages/auth/login/schema/index";
import { useAuthUserController } from "@/services/use-get-auth-user";

export function ForgotPassword() {
    const authUserController = useAuthUserController()
    const [show, setShow] = React.useState<boolean>(false)

    const formik = useFormik({
        onSubmit: async (values) => {
            await authUserController.onLogin(values)
        },
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginSchema,
    })

    if (authUserController.isAuth) {
        return <Navigate to={'/secure'} />
    }

    const type = show ? 'text' : 'password';


    return (
        <React.Fragment>
            <Box sx={{
                width: { md: '30rem', xs: '90%' },
                minHeight: '20rem',
                bgcolor: colors.white, p: 2,
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;'
            }}
                mx="auto">
                <Box sx={{ m: '2rem' }}>
                    <Typography variant="h4">Enter Password</Typography>
                    <Box mt="2rem">
                        <Typography variant="body2" sx={{ color: colors.red, py: 2 }}>
                            {authUserController.errorMessage && authUserController.errorMessage}
                        </Typography>
                        <TextInput
                            label="Email Address"
                            placeholder="davidjames@gmail.com"
                            sxLabel={{ py: 1.5, color: colors.textColor, }}
                            size={'lg'}
                            errorText={
                                formik.touched.email && formik.errors?.email
                                    ? formik.errors.email
                                    : undefined
                            }
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}

                        />
                        <TextInput
                            label="Password"
                            placeholder="********"
                            type={type}
                            sxLabel={{ py: 1.5, color: colors.textColor, }}
                            size={'lg'}
                            errorText={
                                formik.touched.password && formik.errors?.password
                                    ? formik.errors.password
                                    : undefined
                            }
                            name="password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            endDecorator={!show ? <VisibilityOff sx={{ cursor: 'pointer' }} onClick={() => setShow(!show)} /> : <Visibility sx={{ cursor: 'pointer' }} onClick={() => setShow(!show)} />}
                        />
                        <Box sx={{ color: colors.textColor, width: '100%', textAlign: 'right' }}>
                            <Link to="/" style={{ color: colors.primary }}> Forgot Password?</Link>
                        </Box>
                        <Button
                            sx={{ width: '100%', mt: '2.5rem' }}
                            disabled={!formik.isValid || authUserController.isPending}
                            type="submit"
                            fullWidth
                            onClick={() => formik.handleSubmit()}
                        >
                            {authUserController.isPending ? <CircularProgress /> : 'Save'}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}