import React from 'react'
import { colors } from '@/utilities/colors'
import { Box, InputProps, Typography } from '@mui/joy'
import { SxProps } from '@mui/system'
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';


interface OptionType {
    value: string | number;
    text: string;
}

interface TSelectInputProps extends InputProps {
    errorText?: string
    label?: string
    sxLabel?: SxProps
    options: OptionType[],
}

export const SelectInput = React.forwardRef<HTMLSelectElement, TSelectInputProps>(
    ({ label, onChange, errorText, sxLabel, ...props }) => {
        const isError = (errorText || '').trim().length > 0

        const handleChange = (
            event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
            value: {} | null
        ) => {
            if (onChange) {
                const syntheticEvent = {
                    ...event,
                    target: { value }
                };
                // @ts-ignore
                onChange(syntheticEvent);
            }
        };
        return (
            <Box>
                {label && (
                    <Box>
                        <Typography level={'body-sm'} sx={sxLabel}>
                            {label}
                        </Typography>
                    </Box>
                )}
                <Select
                    placeholder={props.placeholder}
                    indicator={<KeyboardArrowDown />}
                    onChange={handleChange}
                    sx={{
                        minHeight: '2.5rem',
                        ...props.sx,
                        border: isError ? `1px solid ${colors.red}` : `1px solid ${colors.textColor}`,
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {

                        props.options.map((option) => {
                            return <Option key={option.value} value={option.value}>{option.text}</Option>
                        })

                    }
                </Select>
                <Typography level={'body-xs'} sx={{ color: colors.red, mb: 1 }}>
                    {errorText}
                </Typography>
            </Box>
        )
    }
)
