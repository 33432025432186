export const colors = {
  primary: "#04144E",
  secondary: "#E9EBF2",
  white: "#FFFFFF",
  black: "#000000",
  gray: "#9C999C",
  lightGray: "#E9EBF2",
  red: "#f00",
  textColor:"#555555",
  buttonHover:"#04144E",
  success:"rgb(31 122 31)",
};
