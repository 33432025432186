import React from "react";
import { Snackbar } from "@mui/joy";

const DURATION = 3000;

interface AnchorOrigin {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
}

interface CustomSnackbarProps {
    message: string | null;
    isOpen: boolean;
    isError?: boolean;
    duration?: number;
    anchorOrigin?: AnchorOrigin;
    startDecorator?: React.ReactNode;
    endDecorator?: React.ReactNode;
    onClose: () => void;
}

export function CustomSnackbar(props: CustomSnackbarProps) {
    const { message, isOpen, onClose, isError, duration, anchorOrigin, startDecorator, endDecorator } = props;
    const [open, setOpen] = React.useState<boolean>(isOpen);

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Snackbar
            autoHideDuration={duration || DURATION}
            open={open}
            color={isError ? 'danger' : 'success'}
            size='lg'
            onClose={(_, reason) => {
                if (reason === 'clickaway') {
                    return;
                }
                setOpen(false);
                onClose();
            }}
            anchorOrigin={anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
            startDecorator={startDecorator}
            endDecorator={endDecorator}
        >
            {message}
        </Snackbar>
    );
}
