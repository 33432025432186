import React from "react";
import { useAdminController } from "@/services/use-admin";
import { Checkin } from "@/services/use-admin/schema";
import { NoData } from "@/components/no-data";
import { SendMessageAlert } from "@/components/send-message";
import { Tooltip } from "@/components/tooltip";
import { colors } from "@/utilities/colors";
import { CircularProgress, List, ListItem, ListItemContent, Box, Button, Sheet, Table, Typography, Card, Grid } from "@mui/joy";
import { isEmpty } from "lodash";
import { DateTime } from 'luxon';
import { ViewDetails } from "@/src/pages/secure/dashboard/modals/ViewDetails";
import { MiniAnimatedSnackbar } from "@/components/snackbar/MiniAnimatedSnackbar";
import * as yup from 'yup'
import { useFormik } from "formik";
import { TextInput } from "@/components/text-input";
import { Header } from "@/src/pages/secure/components/Header";


export const CheckInHistory = () => {
  const { onGetCheckins, isPending, errorMessage } = useAdminController();
  const [checkins, setCheckins] = React.useState<Checkin[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [refreshed, setRefreshed] = React.useState<boolean>(false);


  const fetchCheckins = async (page: number, isInit: boolean) => {
    try {
      const response = await onGetCheckins(page, 10, isInit);
      setCheckins(response.data.data);
      setTotalPages(Math.ceil(response.data.total / response.data.limit));
    } catch (error) {
      console.error("Failed to fetch checkins:", error);
    }
  };


  React.useEffect(() => {
    fetchCheckins(currentPage, true);
   
  }, [ currentPage]);



  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };



  const formik = useFormik({
    onSubmit: async (values) => {
      await onGetCheckins(currentPage, 10, true, values)
    },
    initialValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
    validationSchema: yup.object().shape({
      startDate: yup
        .date()
        .required('Start date is required')
        .max(new Date(), 'Start date cannot be in the future'),
      endDate: yup
        .date()
        .required('End date is required')
        .when('startDate', (startDate, schema) => {
          return schema.test({
            name: 'endDateCheck',
            test: function (endDate) {
              const { path, createError } = this;
              if (endDate && startDate) {
                if (formik.values.endDate < formik.values.startDate) {
                  return createError({ path, message: 'End date must be greater than or equal to start date' });
                }
              }
              return true;
            }
          });
        })
    })
  })


  return (
    <React.Fragment>
      <Grid>
        <Grid padding={0} xs={12}>
          <Header title='Checkin History' />
        </Grid>
        <Grid xs={12} mt={3}>
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', alignContent: 'baseline', ml: 4 }}>
            <TextInput type="date" value={formik.values.startDate as any} label="Start Date" size="sm"
              name="startDate"
              // errorText={
              //   formik.touched.startDate && formik.errors?.startDate
              //     ? formik.errors.startDate
              //     : undefined
              // }
              onChange={formik.handleChange} sx={{ maxWidth: '27rem' }} />
            <TextInput type="date" value={formik.values.endDate as any} label="Start Date" size="sm"
              name="endDate"
              // errorText={
              //   formik.touched.endDate && formik.errors?.endDate
              //     ? formik.errors.endDate
              //     : undefined
              // }
              onChange={formik.handleChange} sx={{ maxWidth: '27rem' }} />
            <Button onClick={() => formik.handleSubmit()}
              size="sm" sx={{ width: '8rem', py: 1, height: '2.5rem', mt: 1.5 }}>Submit</Button>
          </Box>

          {isPending ?
            <Box sx={{ width: '100%', height: '10rem', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box> :
            errorMessage ? <Typography sx={{ color: colors.red, py: 5, px: 3, textAlign: 'center' }}>{errorMessage}</Typography> :
              checkins.length < 1 ? <NoData message="No checkins yet" /> :
                <Sheet sx={{ height: 'calc(100vh - 280px)', overflow: 'auto', mt: '2rem', px: 2 }}>

                  <Table
                    aria-label="table with sticky header"
                    stickyHeader
                    hoverRow
                  >
                    <thead>
                      <tr style={{ backgroundColor: colors.primary }}>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '2rem' }}>S/N</th>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '7rem' }}>First Name</th>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '8rem' }}>Phone Number</th>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '10rem' }}>Email</th>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '3rem' }}>Status</th>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '4rem' }}>CheckIn Time</th>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '4rem' }}>CheckOut Time</th>
                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', textAlign: 'center', width: '4rem' }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkins.map((row: Checkin, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.worker.firstName}</td>
                          <td>{row.worker.phoneNumber}</td>
                          <td>{row.worker.email}</td>
                          <td>{row.status}</td>
                          <td>
                            {DateTime.fromJSDate(new Date(row.createdAt)).toFormat(
                              'ccc - hh:mm a'
                            )}
                          </td>
                          <td>
                            {
                              row.checkoutAt ? DateTime.fromJSDate(new Date(row.checkoutAt)).toFormat(
                                'ccc - hh:mm a'
                              ) : '...'
                            }
                          </td>
                          <td style={{ textAlign: 'center' }} >
                            <Tooltip id={row.workerId}>
                              <Card sx={{ p: 1, bgcolor: colors.white }}>
                                <List component="nav" aria-label="main mailbox folders">
                                  <ListItem>
                                    <ListItemContent>
                                      <ViewDetails user={row} />
                                    </ListItemContent>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemContent sx={{ cursor: 'pointer' }}>
                                      
                                    </ListItemContent>
                                  </ListItem>
                                  <ListItem>
                                    <ListItemContent>
                                      <SendMessageAlert id={row.workerId} lastName={row.worker.firstName} firstName={row.worker.lastName} />
                                    </ListItemContent>
                                  </ListItem>
                                </List>
                              </Card>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Sheet>
          }
          {
            !isPending && isEmpty(errorMessage) && checkins.length > 0 &&
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
              <Button
                variant="outlined"
                color="primary"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Button>
              <Box sx={{ padding: '0 1rem', alignSelf: 'center' }}>
                Page {currentPage} of {totalPages}
              </Box>
              <Button
                variant="outlined"
                color="primary"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Button>
            </Box>
          }
          <MiniAnimatedSnackbar
            message="List refreshing..."
            isOpen={refreshed}
            onClose={() => setRefreshed(false)}
            animationDuration={400}
            duration={2000}
          />
        </Grid>
      </Grid>

    </React.Fragment>
  );
}