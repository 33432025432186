import React from 'react';
import { Header } from '@/src/pages/secure/components/Header';
import { SettingsForm } from '@/src/pages/secure/settings/components/SettingsForm';
import { Grid } from '@mui/material';

export function Settings() {
  return (
    <React.Fragment>
      <Grid>
        <Grid item xs={12}>
          <Header title='Settings' />
        </Grid>
        <Grid item xs={12}>
          <SettingsForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
