import React from "react";
import { TextInput } from "@/components/text-input";
import { colors } from "@/utilities/colors";
import { Box, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, Navigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/joy";
import { useFormik } from 'formik'
import { changePasswordSchema } from "@/src/pages/auth/change-password/schema/index";
import { useAuthUserController } from "@/services/use-get-auth-user";



export function ChangePassword() {
    const authUserController = useAuthUserController()
    const [showPassword, setShowPassword] = React.useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false)

    const formik = useFormik({
        onSubmit: async (values) => {
            const { newPassword, oldPassword } = values;
            await authUserController.changePassword({ oldPassword, newPassword })
        },
        initialValues: {
            oldPassword: '',
            newPassword: '',
            newConfirmPassword: '',
        },
        validationSchema: changePasswordSchema,
    })

    /*  if (authUserController.user && authUserController.user.emailVerified) {
         return <Navigate to={'/dashboard'} />
     } */

    const passwordType = showPassword ? 'text' : 'password';
    const confirmPasswordType = showConfirmPassword ? 'text' : 'password';


    if(!authUserController.isAuth){
        return <Navigate to={'/login'} />
    }

    if(authUserController.user?.emailVerified){
        return <Navigate to={'/dashboard'} />
    }

    return (
        <React.Fragment>
            <Box sx={{
                width: { md: '30rem', xs: '90%' },
                minHeight: '20rem',
                bgcolor: colors.white, p: 2,
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;'
            }}
                mx="auto">
                <Box sx={{ m: '2rem' }}>
                    <Typography variant="h4">Change Password</Typography>
                    <Box mt="2rem">
                        <Typography variant="body2" sx={{ color: colors.red, py: 2 }}>
                            {authUserController.errorMessage && authUserController.errorMessage}
                        </Typography>
                        <TextInput
                            label="Old password (password sent to your email)"
                            placeholder="*******"
                            sxLabel={{ py: 1.5, color: colors.textColor, }}
                            size={'lg'}
                            errorText={
                                formik.touched.oldPassword && formik.errors?.oldPassword
                                    ? formik.errors.oldPassword
                                    : undefined
                            }
                            type="text"
                            name="oldPassword"
                            value={formik.values.oldPassword}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}

                        />
                        <TextInput
                            label="New Password"
                            placeholder="********"
                            type={passwordType}
                            sxLabel={{ py: 1.5, color: colors.textColor, }}
                            size={'lg'}
                            errorText={
                                formik.touched.newPassword && formik.errors?.newPassword
                                    ? formik.errors.newPassword
                                    : undefined
                            }
                            name="newPassword"
                            value={formik.values.newPassword}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            endDecorator={!passwordType ? <VisibilityOff sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} /> : <Visibility sx={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />}
                        />
                        <TextInput
                            label="Confirm New Password"
                            placeholder="********"
                            type={confirmPasswordType}
                            sxLabel={{ py: 1.5, color: colors.textColor, }}
                            size={'lg'}
                            errorText={
                                formik.touched.newConfirmPassword && formik.errors?.newConfirmPassword
                                    ? formik.errors.newConfirmPassword
                                    : undefined
                            }
                            name="newConfirmPassword"
                            value={formik.values.newConfirmPassword}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            endDecorator={!confirmPasswordType ? <VisibilityOff sx={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> : <Visibility sx={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />}
                        />
                        <Box sx={{ color: colors.textColor, width: '100%', textAlign: 'right' }}>
                            <Link to="/" style={{ color: colors.primary }}> Forgot Password?</Link>
                        </Box>
                        <Button
                            sx={{ width: '100%', mt: '2.5rem' }}
                            disabled={!formik.isValid || authUserController.isLoading}
                            type="submit"
                            fullWidth
                            onClick={() => formik.handleSubmit()}
                        >
                            {authUserController.isLoading ? <CircularProgress /> : 'Save'}
                        </Button>
                    </Box>
                </Box>
                
            </Box>
        </React.Fragment>
    )
}