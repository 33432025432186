import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/joy/Button';
import { Box } from '@mui/joy';
import { useFormik } from 'formik';
import { myProfileSchema } from '@/src/pages/secure/profile/my-profile/schema';
import { TextInput } from '@/components/text-input';
import { colors } from '@/utilities/colors';
import { SelectInput } from '@/components/select-input';
import { useStore } from '@/src/store/user';
//import { useAdminController } from '@/services/use-admin';

export function MyProfileForm() {
    const { userInfo } = useStore();
    //const adminController = useAdminController();

    const formik = useFormik({
        onSubmit: async (values) => {
            console.log(values);
            
            //await adminController.createAdmin(values)
        },
        initialValues: {
            first_name: '',
            last_name: '',
            gender: '',
            phone_number: '',
            dob: '',
            label: '',
        },
        validationSchema: myProfileSchema,
    });

    useEffect(() => {
        if (userInfo) {
            formik.setValues({
                first_name: userInfo.firstName || '',
                last_name: userInfo.lastName || '',
                gender: userInfo.gender || '', 
                phone_number: userInfo.phoneNumber || '',
                dob: userInfo.dob || '', 
                label: userInfo.label || '', 
            });
        }
    }, [userInfo]);

    return (
        <React.Fragment>
            <Box sx={{}}>
                <Box sx={{ width: { xs: '100%', sm: '80%', md: '40rem' } }}>
                    <TextInput
                        label="First Name"
                        placeholder="Enter First Name"
                        sxLabel={{ py: 1.5, color: colors.textColor }}
                        size="lg"
                        errorText={
                            formik.touched.first_name && formik.errors?.first_name
                                ? formik.errors.first_name
                                : undefined
                        }
                        type="text"
                        name="first_name"
                        value={formik.values.first_name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <TextInput
                        label="Last Name"
                        placeholder="Enter Last Name"
                        sxLabel={{ py: 1.5, color: colors.textColor }}
                        size="lg"
                        errorText={
                            formik.touched.last_name && formik.errors?.last_name
                                ? formik.errors.last_name
                                : undefined
                        }
                        type="text"
                        name="last_name"
                        value={formik.values.last_name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <SelectInput
                        options={[
                            { value: 1, text: 'Male' },
                            { value: 2, text: 'Female' },
                        ]}
                        placeholder="Select Gender"
                        name="gender"
                        label="Gender"
                        sxLabel={{ py: 1.5, color: colors.textColor }}
                        errorText={
                            formik.touched.gender && formik.errors?.gender
                                ? formik.errors.gender
                                : undefined
                        }
                        value={formik.values.gender}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <TextInput
                        name="dob"
                        label="Date of Birth"
                        sxLabel={{ py: 1.5, color: colors.textColor }}
                        errorText={
                            formik.touched.dob && formik.errors?.dob
                                ? formik.errors.dob
                                : undefined
                        }
                        type="date"
                        value={formik.values.dob}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <TextInput
                        label="Phone Number"
                        startDecorator="+234"
                        sxLabel={{ py: 1.5, color: colors.textColor }}
                        size="lg"
                        errorText={
                            formik.touched.phone_number && formik.errors?.phone_number
                                ? formik.errors.phone_number
                                : undefined
                        }
                        type="text"
                        name="phone_number"
                        value={formik.values.phone_number}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <TextInput
                        label="Label"
                        sxLabel={{ py: 1.5, color: colors.textColor }}
                        size="lg"
                        errorText={
                            formik.touched.label && formik.errors?.label
                                ? formik.errors.label
                                : undefined
                        }
                        type="text"
                        name="label"
                        value={formik.values.label}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                    <Box sx={{ mt: '2rem' }}>
                        <Button sx={{ width: '100%' }} onClick={() => formik.handleSubmit()}>Save</Button>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
