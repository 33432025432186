import React from 'react';
import { Header } from '@/src/pages/secure/components/Header';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { colors } from '@/utilities/colors';
import { AdminTableInfo } from '@/src/pages/secure/profile/components/AdminTableInfo';
import { WorkerTableInfo } from '@/src/pages/secure/profile/components/WorkerTableInfo';
import { Grid } from '@mui/material';

export default function TableTabs() {
  return (
    <Tabs aria-label="TableTabs" defaultValue={0}>
      <TabList>
        <Tab sx={{
          fontWeight: 600,
          fontSize: 20,
          border: 'none',
          minWidth: '20rem',
          py: '1rem',
          '&:focus': { outline: '0' },
          '&.Mui-selected': { backgroundColor: colors.white, color: colors.primary }
        }}>Admin Information</Tab>
        <Tab sx={{
          fontWeight: 600,
          fontSize: 20,
          border: 'none',
          minWidth: '20rem',
          py: '1rem',
          '&:focus': { outline: '0' },
          '&.Mui-selected': { backgroundColor: colors.white, color: colors.primary }
        }}>Worker Information</Tab>
      </TabList>
      <TabPanel value={0} sx={{ px: 0 }}>
        <AdminTableInfo />
      </TabPanel>
      <TabPanel value={1}>
        <WorkerTableInfo />
      </TabPanel>
    </Tabs>
  );
}


export function Profile() {
  return (
    <React.Fragment>
      <Grid>
        <Grid padding={0} item xs={12}>
        <Header title='Profile' />
        </Grid>
        <Grid item xs={12}>
        <TableTabs />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
