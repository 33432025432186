import { colors } from "@/utilities/colors";
import { Box } from "@mui/joy";

export const Header = (props: { title: string }) =>{
    return (
        <Box sx={{
            fontWeight: 600,
            py: 1,
            color: colors.white,
            fontSize: '1.15rem',
            backgroundColor: colors.primary,
            pl: 5
        }}>
            {props.title}
        </Box>
    )
}