export interface ErrorResponse {
    response?: {
        data?: {
            message?: string | string[];
        };
    };
    code?: number | string;
    message?: string;
}

export function getErrorMessage(e: unknown): string {
    const error = e as ErrorResponse;
    const message = error.response?.data?.message;
    if (Array.isArray(message)) {
        return message[0];
    } else if (typeof message === 'string') {
        return message;
    } else if (error.code === "ERR_NETWORK") {
        return error.message || 'Oops! It looks like you are offline. Check your internet connection.';
    } else {
        return 'An unknown error occurred';
    }
}
