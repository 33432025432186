import { PropsWithChildren, useCallback, useState, useContext, createContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getErrorMessage } from '@/utilities/get-error-message';
import { useStore } from "@/src/store/user";
import { Axios } from '@/utilities/axios';
import { User } from '@/services/use-admin/schema';
import { CustomSnackbar } from '@/components/snackbar';

const SESSION_DURATION_IN_MINS = 10800;

function useGetAuthUser() {
    const { setUser } = useStore();
    const [user, setLocalUser] = useState<User | null>(null);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);

    useEffect(() => {
        const userInfo = localStorage.getItem('user_info');
        const token = Cookies.get('access_token');

        if (userInfo && token) {
            const user = JSON.parse(userInfo) as User;
            setUser(user);
            setLocalUser(user);
            setIsAuth(true);
            setIsAuthenticating(false);
        }
        setIsAuthenticating(false);
    }, [setUser]);

    return {
        user,
        setIsAuth,
        isAuth,
        isAuthenticating,
        setIsAuthenticating,
        isLoading: false,
        error: null,
    };
}

function useAuthUser() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const { setUser } = useStore();
    const { user, isAuth, setIsAuth, isAuthenticating, setIsAuthenticating } = useGetAuthUser();

    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const logout = useCallback(() => {
        sessionStorage.removeItem('session');
        localStorage.removeItem('user_info');
        Cookies.remove('access_token');
        setUser(null);
        setIsAuth(false);
    }, []);

    const onUserLogin = useCallback(
        async (user: { email: string; password: string }) => {
            try {
                setIsLoading(true);
                setIsAuthenticating(true);
                await Axios.post('/api/v1/auth/login', {
                    email: user.email,
                    password: user.password,
                    session_duration_minutes: SESSION_DURATION_IN_MINS,
                }, {
                    headers: {
                        'client': 'admin'
                    }
                }).then(response => {
                    const userData = response.data.data;

                    setUser({
                        id: userData.id,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email,
                        phoneNumber: userData.phoneNumber,
                    });

                    const accessToken = userData.accessToken;
                    localStorage.setItem('user_info', JSON.stringify(userData));

                    Cookies.set('access_token', accessToken, { expires: SESSION_DURATION_IN_MINS / 1440 });

                    setIsAuth(true);
                    setIsLoading(false);
                    setIsAuthenticating(false);
                    //navigate('/dashboard');
                    <Navigate to="/dashboard" />
                });


            } catch (e) {
                setIsLoading(false);
                console.error(e);
                setIsAuth(false);
                setErrorMessage(getErrorMessage(e));
                setSnackbarOpen(true);
            }
        },
        [navigate, setUser]
    );

    const onChangePassword = useCallback(
        async (user: { oldPassword: string; newPassword: string }) => {
            try {
                setIsLoading(true);
                await Axios.put('/api/v1/admin/change-password', {
                    oldPassword: user.oldPassword,
                    newPassword: user.newPassword,
                }, {
                    headers: {
                        'client': 'admin',
                        'Authorization': 'Bearer ' + Cookies.get('access_token'),
                    }
                });
                localStorage.removeItem('user_info');
                setIsAuth(false);
                setUser(null);
                navigate('/login');
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                setErrorMessage(getErrorMessage(e));
                setSnackbarOpen(true);
            }
        },
        [navigate, setUser]
    );

    return {
        isPending: isAuthenticating,
        isLoading: isLoading,
        isAuth,
        logout,
        user,
        onLogin: onUserLogin,
        changePassword: onChangePassword,
        errorMessage,
        snackbarOpen,
        setSnackbarOpen,
    };
}

type AuthUserContext = ReturnType<typeof useAuthUser>;
const Context = createContext<AuthUserContext | undefined>(undefined);

export function useAuthUserController() {
    const context = useContext(Context);
    if (!context) {
        throw new Error('You must wrap with AuthUserProvider to use this hook');
    }
    return context;
}

type TAuthUserProvider = PropsWithChildren<{
    value?: AuthUserContext;
}>;

export function AuthUserProvider(props: TAuthUserProvider) {
    const value = useAuthUser();
    return (
        <Context.Provider value={props.value || value}>
            {props.children}
            <CustomSnackbar
                message={value.errorMessage}
                isOpen={value.snackbarOpen}
                isError={value.errorMessage ? true : false}
                duration={3000}
                onClose={() => value.setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: "right" }}
            />
        </Context.Provider>
    );
}
