import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Box, CircularProgress, Option, Select, Typography, selectClasses } from '@mui/joy';
import { useFormik } from 'formik';
import { adminSchema } from '@/src/pages/secure/profile/modals/create-admin-modal/schema';
import { TextInput } from '@/components/text-input';
import { colors } from '@/utilities/colors';
import { SelectInput } from '@/components/select-input';
import { useAdminController } from '@/services/use-admin';
import 'react-phone-number-input/style.css';
import PhoneInput, { getCountries } from 'react-phone-number-input';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Role } from '@/services/use-admin/schema';

export function CreateAdminModal() {
  const adminController = useAdminController();
  const [open, setOpen] = React.useState<boolean>(false);
  const allowedCountries = ['US', 'CA', 'GB', 'AU', 'NG', 'GH'];
  const countries = getCountries().filter(country => allowedCountries.includes(country));
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchRoles = async () => {
    try {
      const response = await adminController.onGetRoles();
      setRoles(response.data);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  };

  const formik = useFormik({
    onSubmit: async (values) => {
      setLoading(true);
      await adminController.onCreateAdmin(values).then(() => {
        setLoading(false)
        formik.resetForm();
      }).catch(() => {
        setLoading(false)
      })
    },
    initialValues: {
      firstName: '',
      lastName: '',
      gender: '',
      roleId: '',
      phoneNumber: '',
      email: '',
    },
    validationSchema: adminSchema,
  });


  React.useEffect(() => {
    fetchRoles();
  }, []);

  const handleSelectChange = ( newValue: string | null) => {
    formik.setFieldValue('roleId', newValue);
  };
  

  return (
    <React.Fragment>
      <Button
        sx={{ '&:focus': { outline: 'none' } }}
        onClick={() => setOpen(true)}>
        Create Admin
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} >
        <ModalDialog sx={{ minWidth: { xs: '100%', sm: '80%', md: '30rem', maxHeight: '100%', overflowY: 'auto' } }}>
          <Typography level='body-sm' py={1}>
            Enter admin details
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit}>
            <TextInput
              label="First Name"
              placeholder="Enter First Name"
              sxLabel={{ py: 1.5, color: colors.textColor }}
              size="lg"
              errorText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : undefined}
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <TextInput
              label="Last Name"
              placeholder="Enter Last Name"
              sxLabel={{ py: 1.5, color: colors.textColor }}
              size="lg"
              errorText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : undefined}
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <SelectInput
              options={[
                { value: 1, text: 'Male' },
                { value: 2, text: 'Female' },
              ]}
              placeholder="Select Gender"
              name="gender"
              label="Gender"
              sxLabel={{ py: 1.5, color: colors.textColor }}
              errorText={formik.touched.gender && formik.errors.gender ? formik.errors.gender : undefined}
              value={formik.values.gender}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Box>
              <Box>
                <Typography level="body-sm" sx={{ py: 1.5, color: colors.textColor }}>
                  Select Role
                </Typography>
              </Box>
              <Select
                placeholder="Select admin role"
                indicator={<KeyboardArrowDown />}
                onChange={(_, newValue) => handleSelectChange(newValue)}
                name="roleId"
                value={formik.values.roleId}
                sx={{
                  minHeight: '2.5rem',
                  border: formik.errors.roleId ? `1px solid ${colors.red}` : `1px solid ${colors.textColor}`,
                  [`& .${selectClasses.indicator}`]: {
                    transition: '0.2s',
                    [`&.${selectClasses.expanded}`]: {
                      transform: 'rotate(-180deg)',
                    },
                  },
                }}
              >
                {(roles || []).map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
              <Typography level="body-xs" sx={{ color: colors.red, mb: 1 }}>
                {formik.errors.roleId}
              </Typography>
            </Box>
            <Box>
              <Typography level="body-sm" sx={{ py: 1.5, color: colors.textColor }}>
                Phone Number
              </Typography>
              <PhoneInput
                countryCallingCodeEditable={false}
                international
                countries={countries}
                defaultCountry="CA"
                placeholder="Enter phone number"
                value={formik.values.phoneNumber}
                onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                style={{
                  minHeight: '2.5rem',
                  border: formik.touched.phoneNumber && formik.errors.phoneNumber ? `1px solid ${colors.red}` : `1px solid ${colors.textColor}`,
                }}
              />
              <Typography level="body-xs" sx={{ color: colors.red, mb: 1 }}>
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : ''}
              </Typography>
            </Box>
            <TextInput
              label="Email"
              placeholder="nuru@gmail.com"
              sxLabel={{ py: 1.5, color: colors.textColor }}
              size="lg"
              errorText={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
              type="email"
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button
                disabled={loading}
                sx={{ minWidth: '100%', mx: 'auto', '&:focus': { outline: 'none' } }}
                type="submit">
                {loading ? <CircularProgress /> : 'Create'}
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
