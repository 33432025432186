import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const passwordRequirements = z.string()
    .min(8, 'Password must not be less than 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/\d/, 'Password must contain at least one number')
    .regex(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character');


const schema = z.object({
    oldPassword: passwordRequirements,
    newPassword: passwordRequirements,
    newConfirmPassword: passwordRequirements,
}).refine(data => data.newPassword === data.newConfirmPassword, {
    message: "New password and confirm new password must match",
    path: ["newConfirmPassword"], 
}).refine(data => data.oldPassword !== data.newPassword, {
    message: "Old password and new password must not be the same",
    path: ["newPassword"], 
});

export const changePasswordSchema = toFormikValidationSchema(schema);
