import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const schema = z.object({
    email: z.string({
        'message':'Email field is Required'
    }).email('It must be a valid email address'),
    firstName: z.string({'message':'First Name field is Required'}),
    lastName: z.string({'message':'Last Name field is Required'}),
    phoneNumber: z.string({ message: 'Phone Number field is Required' }).refine(
        (value) => isValidPhoneNumber(value || ''),
        { message: 'Invalid Phone Number' }
      ),
})

export const workerSchema = toFormikValidationSchema(schema)
