import React from 'react'
import { colors } from '@/utilities/colors'
import { Box, Input, InputProps, Typography } from '@mui/joy'
import { SxProps } from '@mui/system'

interface TTextInputProps extends InputProps {
    errorText?: string
    label?: string
    sxLabel?: SxProps
}

export const TextInput = React.forwardRef<HTMLInputElement, TTextInputProps>(
    ({ label, onChange,  errorText, sxLabel, ...props }, ref) => {
        const isError = (errorText || '').trim().length > 0

        return (
            <Box>
                {label && (
                    <Box>
                        <Typography level={'body-sm'} sx={sxLabel}>
                            {label}
                        </Typography>
                    </Box>
                )}
                <Input
                    ref={ref}
                    {...props}
                    onChange={onChange}
                    sx={{
                        minHeight:'2.5rem',
                        ...props.sx,
                        border: isError ? `1px solid ${colors.red}` : `1px solid ${colors.textColor}`,
                    }}
                />
                <Typography level={'body-xs'} sx={{ color: colors.red, mb: 1 }}>
                    {errorText}
                </Typography>
            </Box>
        )
    }
)
