import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { Box, Textarea } from '@mui/joy';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Axios } from '@/utilities/axios';
import Cookies from 'js-cookie';
import { getErrorMessage } from '@/utilities/get-error-message';
import { colors } from '@/utilities/colors';

export const SendMessageAlert = (props: { id: string | number | undefined, firstName: string, lastName: string }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const validationSchema = Yup.object({
        workerId: Yup.string().required('Worker ID is required'),
        message: Yup.string().required('Message is required')
    });

    const formik = useFormik({
        onSubmit: async (values) => {
            try {
                await Axios.post('/api/v1/admin/message/alert', {
                    workerId: values.workerId,
                    message: values.message,
                }, {
                    headers: {
                        'client': 'admin',
                        'Authorization': 'Bearer ' + Cookies.get('access_token'),
                    }
                });
                setOpen(false)
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false);
                setErrorMessage(getErrorMessage(e))
            }
        },
        initialValues: {
            workerId: props.id,
            message: ''
        },
        validationSchema
    })
    return (
        <React.Fragment>
            <Typography level="body-sm" onClick={() => setOpen(true)} sx={{cursor:'pointer'}}>
                Send Message Alert
            </Typography>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        minWidth: 500,
                        maxWidth: 700,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Box
                        id="modal-title"
                        mb={1}
                        sx={{ display: 'grid', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
                    >
                        <Typography
                            component="h2"
                            level="h4"
                            fontWeight="lg"
                            textColor="inherit"
                        >
                            Send Message Alert to
                        </Typography>
                        <Typography>{props.firstName + ' ' + props.lastName}</Typography>
                    </Box>
                    {errorMessage &&
                        <Typography sx={{ color: colors.red, textAlign: 'center' }}>{errorMessage}</Typography>
                    }
                    <Box mt='1.5rem' mb='1rem'>
                        <Textarea
                            name='message'
                            value={formik.values.message}
                            sx={{ minHeight: '8rem', width: '100%' }}
                            onChange={formik.handleChange}
                        />
                    </Box>
                    <Box display="flex" justifyContent="end" mt='1rem'>
                        <Button sx={{ width: '8rem' }}
                            disabled={!formik.isValid || isLoading}
                            fullWidth
                            onClick={() => formik.handleSubmit()}
                        >Send Message</Button>
                    </Box>
                </Sheet>
            </Modal>
        </React.Fragment>
    );
}