import React, { useState } from "react";
import { useAdminController } from "@/services/use-admin";
import { Checkin } from "@/services/use-admin/schema";
import { NoData } from "@/components/no-data";
import { SendMessageAlert } from "@/components/send-message";
import { Tooltip } from "@/components/tooltip";
import { colors } from "@/utilities/colors";
import { AspectRatio, CircularProgress, List, ListItem, ListItemContent, Box, Button, Sheet, Table, Typography, Card } from "@mui/joy";
import { isEmpty } from "lodash";
import { DateTime } from 'luxon';
import { ViewDetails } from "@/src/pages/secure/dashboard/modals/ViewDetails";
import { GoogleMapsView, DEFAULT_ZOOM_VALUE } from "@/components/google-maps-view";

interface centerCords {
    lat: number
    lng: number
}

export const CheckinList = () => {
    const { onGetCheckins, isPending, errorMessage } = useAdminController();
    const [checkins, setCheckins] = React.useState<Checkin[]>([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [zoom, setZoom] = React.useState<number>(DEFAULT_ZOOM_VALUE);
    const [monitoring, setMonitoring] = React.useState<boolean>(false);
    const [runInterval, setRunInterval] = React.useState<boolean>(true);
    const [center, setCenter] = React.useState<centerCords>({ lat: 5.614818, lng: -0.205874 });


    const fetchCheckins = async (page: number, isInit: boolean) => {
        try {
            const response = await onGetCheckins(page, 10, isInit, {startDate:new Date(), endDate:new Date()});
            setCheckins(response.data.data);
            setTotalPages(Math.ceil(response.data.total / response.data.limit));
        } catch (error) {
            console.error("Failed to fetch checkins:", error);
        }
    };


    React.useEffect(() => {
        runInterval && fetchCheckins(currentPage, true);
        const intervalId = setInterval(() => {
            if (runInterval) {
                fetchCheckins(currentPage, false);
            }

        }, 20000);

        return () => clearInterval(intervalId)
    }, [runInterval, currentPage]);

    React.useEffect(() => {
        if(runInterval) {
            if (checkins.length > 0) {
                setCenter({
                    lat: parseFloat(checkins[0].latitude) || 5.614818,
                    lng: parseFloat(checkins[0].longitude) || -0.205874,
                });
            }
        }
    }, [checkins, runInterval]);


    const handlePageChange = (newPage: number) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };


    function monitorWorker(cords: Checkin) {
        setRunInterval(false)
        const filteredCheckins = checkins.filter(checkin =>
            parseFloat(checkin.latitude) === parseFloat(cords.latitude) &&
            parseFloat(checkin.longitude) === parseFloat(cords.longitude)
        );
        setCenter({ lat: parseFloat(cords.latitude), lng: parseFloat(cords.longitude) });
        setZoom(16);
        setMonitoring(true);
        setCheckins(filteredCheckins);
    }

    function unMonitorWorker() {
        setRunInterval(true);
        setCenter({ lat: (+checkins[0]?.latitude), lng: (+checkins[0]?.longitude) });
        setZoom(DEFAULT_ZOOM_VALUE)
        setMonitoring(false);
    }


    return (
        <React.Fragment>
            <AspectRatio minHeight="180px" maxHeight="300px">
                <GoogleMapsView checkins={checkins || []}
                    containerStyle={{ width: 'inherit', height: '300px' }}
                    center={center}
                    zoom={zoom}
                    monitoring={monitoring}
                />
            </AspectRatio>
            <Sheet sx={{ height: 300, overflow: 'auto', mt: '2rem', px: 2 }}>
                {isPending ?
                    <Box sx={{ width: '100%', height: '10rem', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box> :
                    errorMessage ? <Typography sx={{ color: colors.red, py: 5, px: 3, textAlign: 'center' }}>{errorMessage}</Typography> :
                        checkins.length < 1 ? <NoData message="No checkins yet" /> :
                            <Table
                                aria-label="table with sticky header"
                                stickyHeader
                                hoverRow
                            >
                                <thead>
                                    <tr style={{ backgroundColor: colors.primary }}>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '2rem' }}>S/N</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '7rem' }}>Name</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '8rem' }}>Phone Number</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '10rem' }}>Email</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '3rem' }}>Status</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', width: '4rem' }}>CheckIn Time</th>
                                        <th style={{ backgroundColor: colors.primary, color: colors.white, verticalAlign: 'middle', textAlign: 'center', width: '4rem' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {checkins.map((row: Checkin, index) => (
                                        <tr key={row.id}>
                                            <td>{index + 1}</td>
                                            <td>{row.worker.firstName + ' ' + row.worker.lastName}</td>
                                            <td>{row.worker.phoneNumber}</td>
                                            <td>{row.worker.email}</td>
                                            <td>{row.status}</td>
                                            <td>
                                                {DateTime.fromJSDate(new Date(row.createdAt)).toFormat(
                                                    'ccc - hh:mm a'
                                                )}
                                            </td>
                                            <td style={{ textAlign: 'center' }} >
                                                <Tooltip id={row.workerId}>
                                                    <Card sx={{ p: 1, bgcolor: colors.white }}>
                                                        <List component="nav" aria-label="main mailbox folders">
                                                            <ListItem>
                                                                <ListItemContent>
                                                                    <ViewDetails user={row} />
                                                                </ListItemContent>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemContent sx={{ cursor: 'pointer' }}>
                                                                    {
                                                                        !monitoring ?
                                                                            <Typography level="body-sm" onClick={() => monitorWorker(row)}>Monitor Worker</Typography>
                                                                            :
                                                                            <Typography level="body-sm" onClick={() => unMonitorWorker()}>unMonitor Worker</Typography>
                                                                    }
                                                                </ListItemContent>
                                                            </ListItem>
                                                            <ListItem>
                                                                <ListItemContent>
                                                                    <SendMessageAlert id={row.workerId} lastName={row.worker.firstName} firstName={row.worker.lastName} />
                                                                </ListItemContent>
                                                            </ListItem>
                                                        </List>
                                                    </Card>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                }
                {
                    !isPending && isEmpty(errorMessage) &&
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: '1rem' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </Button>
                        <Box sx={{ padding: '0 1rem', alignSelf: 'center' }}>
                            Page {currentPage} of {totalPages}
                        </Box>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </Button>
                    </Box>
                }
            </Sheet>
           {/*  <MiniAnimatedSnackbar
                isOpen={refreshed}
                onClose={() => setRefreshed(false)}
                animationDuration={400}
                duration={2000}
            /> */}
        </React.Fragment>
    );
}