import { PropsWithChildren, useState, useContext, createContext } from 'react';
import { Axios } from '@/utilities/axios';
import { Settings, User } from '@/services/use-admin/schema';
import Cookies from 'js-cookie';
import { getErrorMessage } from '@/utilities/get-error-message';
import { CustomSnackbar } from '@/components/snackbar';

/* function useGetAuthUser(enabled: boolean) {
    return useQuery({
        queryKey: [keys.GET_USER.key],
        queryFn: async (): Promise<User> => {
            const res: AxiosResponse<User> = await Axios.get(keys.GET_USER.url);
            return userSchema.parse(res.data);
        },
        retry: 1,
        networkMode: 'offlineFirst',
        enabled,
    });
} */

function useAdmin() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const [isError, setIsError] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const onCreateAdmin = async (user: User) => {
        try {
            setSnackbarMessage(null);
            setIsError(false)
            const res = await Axios.post('/api/v1/admin/register', {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                gender: user.gender,
                roleId: user.roleId,
                phoneNumber: user.phoneNumber,
            }, {
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setSnackbarMessage(res.data.message);
            setSnackbarOpen(true)
        } catch (e) {
            setSnackbarMessage(getErrorMessage(e));
            setIsError(true)
            setSnackbarOpen(true)
        }
    };

    const onCreateWorker = async (user: User) => {
        try {
            setSnackbarMessage(null);
            setIsError(false)
            const res = await Axios.post('/api/v1/admin/register/worker', {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                gender: user.gender,
                phoneNumber: user.phoneNumber,
            }, {
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setSnackbarMessage(res.data.message);
            setSnackbarOpen(true)
        } catch (e) {
            setSnackbarMessage(getErrorMessage(e));
            setIsError(true)
            setSnackbarOpen(true)
        }
    };

    const onGetAdmins = async (page = 1, limit = 10) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const res = await Axios.get('/api/v1/admin', {
                params: { page, limit },
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setIsLoading(false);
            return res.data;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrorMessage(getErrorMessage(e));
            throw e;
        }
    };



    const onGetWorkers = async (page = 1, limit = 10) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const res = await Axios.get('/api/v1/admin/worker', {
                params: { page, limit },
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setIsLoading(false);
            return res.data;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrorMessage(getErrorMessage(e));
            throw e;
        }
    };


    const fetchCheckIns = async (page = 1, limit = 10) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const res = await Axios.get('/api/v1/checkin', {
                params: { page, limit },
                headers: {
                    'client': 'admin',
                    'X-Device-Id': 32232,
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setIsLoading(false);
            return res.data;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrorMessage(getErrorMessage(e));
            throw e;
        }
    };



    const getConfigSettings = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const res = await Axios.get('/api/v1/config-settings', {
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setIsLoading(false);
            return res.data;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrorMessage(getErrorMessage(e));
            throw e;
        }
    };



    const onUpdateSettings = async (values: Settings) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const res = await Axios.put('/api/v1/config-settings', {
                emergencyNumber: values.emergencyNumber,
                defaulterReportingNumber: values.defaulterReportingNumber,
                alertTime: values.alertTime,
                moveAroundTime: values.moveAroundTime,
                messageNotificationNumber: values.messageNotificationNumber,
                messageNotificationEmail: values.messageNotificationEmail,
            }, {
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            
            setSuccessMessage(res.data.message);
            setIsLoading(false);
            setSnackbarMessage(res.data.message);
            setSnackbarOpen(true)
        } catch (e) {
            setIsLoading(false);
            setSnackbarMessage(getErrorMessage(e));
            setIsError(true);
            setSnackbarOpen(true)
        }
    };


    const sendMessageAlert = async (values: { workerId: string | number, message: string }) => {
        try {
            const res = await Axios.post('/api/v1/admin/message/alert', {
                workerId: values.workerId,
                message: values.message,
            }, {
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            return res
        } catch (e) {
            return e
        }
    };


    const onGetCheckins = async (page = 1, limit = 10, isInit: boolean, date?: { startDate: Date, endDate: Date },) => {
        try {
            isInit ?? setIsLoading(true);
            setErrorMessage(null);
            const res = await Axios.get('/api/v1/checkin', {
                params: { page, limit, startDate: date?.startDate, endDate: date?.startDate, checkoutAt:null },
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setIsLoading(false);
            return res.data;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrorMessage(getErrorMessage(e));
            throw e;
        }
    };




    const onGetRoles = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(null);
            const res = await Axios.get('/api/v1/role', {
                headers: {
                    'client': 'admin',
                    'Authorization': 'Bearer ' + Cookies.get('access_token'),
                }
            });
            setIsLoading(false);
            return res.data;
        } catch (e) {
            setIsLoading(false);
            console.error(e);
            setErrorMessage(getErrorMessage(e));
            throw e;
        }
    };

    return {
        isPending: isLoading,
        errorMessage: errorMessage,
        successMessage: successMessage,
        setErrorMessage: setErrorMessage,
        setSuccessMessage: setSuccessMessage,
        onCreateAdmin: onCreateAdmin,
        onCreateWorker: onCreateWorker,
        onGetAdmins: onGetAdmins,
        onGetWorkers: onGetWorkers,
        fetchCheckIns: fetchCheckIns,
        getConfigSettings: getConfigSettings,
        onUpdateSettings: onUpdateSettings,
        sendMessageAlert: sendMessageAlert,
        onGetCheckins: onGetCheckins,
        onGetRoles: onGetRoles,
        snackbarOpen,
        setSnackbarOpen,
        isError,
        snackbarMessage: snackbarMessage,
    };
}

type AdminUserContext = ReturnType<typeof useAdmin>;
const Context = createContext<AdminUserContext | undefined>(undefined);

export function useAdminController() {
    const context = useContext(Context);
    if (!context) {
        throw new Error('You must wrap with AuthUserProvider to use this hook');
    }
    return context;
}

type TAdminUserProvider = PropsWithChildren<{
    value?: AdminUserContext;
}>;

export function AdminUserProvider(props: TAdminUserProvider) {
    const value = useAdmin();
    return (
        <Context.Provider value={props.value || value}>
            {props.children}
            <CustomSnackbar
                message={value.snackbarMessage}
                isOpen={value.snackbarOpen}
                isError={value.isError ? true : false}
                duration={3000}
                onClose={() => value.setSnackbarOpen(false)}
            />
        </Context.Provider>
    );
}
