import {create} from 'zustand';
import { User } from '@/services/use-admin/schema';


interface UserState {
    userInfo: User | null;
  setUser: (userInfo: User | null) => void;
}

/* interface User {
  id: number;
  name: string;
} */

// Create the zustand store
export const useStore = create<UserState>((set) => ({
  userInfo: null,
  setUser: (userInfo) => set({ userInfo }),
}));
