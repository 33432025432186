import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { Box } from '@mui/joy';
import { colors } from '@/utilities/colors';
import { User } from '@/services/use-admin/schema';
import VerifiedIcon from '@mui/icons-material/Verified';

export const ViewDetails = (props: { user: User, title: string}) => {
    const user = props.user
    
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            <Typography level="body-sm" onClick={() => setOpen(true)} sx={{cursor:'pointer'}}>
                View Details
            </Typography>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        minWidth: 600,
                        maxWidth: 750,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Box
                        id="modal-title"
                        mb={1}
                        sx={{ display: 'flex', gap: 2, flexDirection: 'column', textAlign: 'left' }}
                    >
                        <Typography
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                                color: colors.primary,
                                fontWeight: 'semibold',
                            }}
                            level='h4'
                        >
                           {props.title} 
                            </Typography>

                        <Box display='flex' justifyContent="space-between">
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                }}>
                                <Typography sx={{
                                    color: colors.black,
                                }}>
                                    First Name:
                                </Typography>
                                <Typography sx={{
                                    color: colors.gray,
                                }}>
                                    {user.firstName}
                                </Typography>

                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                }}>
                                <Typography sx={{
                                    color: colors.black,
                                }}>
                                    Last Name:
                                </Typography>
                                <Typography sx={{
                                    color: colors.gray,
                                }}>
                                    {user.lastName}
                                </Typography>

                            </Box>
                        </Box>


                        <Box display='flex' justifyContent="space-between">
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                }}>
                                <Typography sx={{
                                    color: colors.black,
                                }}>
                                    Email:
                                </Typography>
                                <Typography sx={{
                                    color: colors.gray,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    {user.email}<VerifiedIcon color='success' />
                                </Typography>

                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                }}>
                                <Typography sx={{
                                    color: colors.black,
                                }}>
                                    Phone Number:
                                </Typography>
                                <Typography sx={{
                                    color: colors.gray,
                                }}>
                                    {user.phoneNumber}
                                </Typography>

                            </Box>
                        </Box>

                        <Box display='flex' justifyContent="space-between">
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                }}>
                                <Typography sx={{
                                    color: colors.black,
                                }}>
                                    Gender:
                                </Typography>
                                <Typography sx={{
                                    color: colors.gray,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    {user.gender ? user.gender : null}
                                </Typography>

                            </Box>
                        </Box>
                    </Box>
                </Sheet>
            </Modal>
        </React.Fragment>
    );
}