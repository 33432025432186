import * as React from 'react';
import Snackbar from '@mui/joy/Snackbar';
import { keyframes } from '@mui/system';

const inAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const outAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`;

interface ISnackProps {
  message?: string | null;
  isOpen: boolean;
  duration?: number;
  animationDuration?: number;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  onClose: () => void;
}

export const MiniAnimatedSnackbar = (props: ISnackProps) => {
  const animationDuration = props.animationDuration || 600;
  return (
    <div>
      <Snackbar
        size='sm'
        color='success'
        variant='solid'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={props.isOpen}
        onClose={props.onClose}
        autoHideDuration={props.duration || 4000}
        animationDuration={animationDuration}
        sx={{
          maxWidth:'50px',
          ...(props.isOpen && {
            animation: `${inAnimation} ${animationDuration}ms forwards`,
          }),
          ...(!props.isOpen && {
            animation: `${outAnimation} ${animationDuration}ms forwards`,
          }),
        }}
      >
        {props.message || ''}
      </Snackbar>
    </div>
  );
}
