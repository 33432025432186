import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { Checkin } from '@/services/use-admin/schema'
import { CheckinMarker } from '@/components/google-maps-view/components/checkin-marker'


export const DEFAULT_ZOOM_VALUE = 12

const mapTheme = [
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f7f1df',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [
            {
                color: '#d0e3b4',
            },
        ],
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        elementType: 'geometry',
        stylers: [
            {
                color: '#fbd3da',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#bde6ab',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffe15f',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#efd151',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: 'black',
            },
        ],
    },
    {
        featureType: 'transit.station.airport',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#cfb2db',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#a2daf2',
            },
        ],
    },
]

interface IGoogleMapsProps {
    containerStyle?: {
        width: number | string
        height: number | string
    }
    checkins?: Checkin[]
    center?: {
        lat: number
        lng: number
    }
    clickedCoords?: {
        lat: number
        lng: number
    } | null
    zoom?: number
    isSelected?: boolean
    selectedCheckin?: Checkin
    onClick?: (e: google.maps.MapMouseEvent) => void
    monitoring: boolean
}

const containerStyle = {
    width: '100%',
    height: '100%',
}

export function GoogleMapsView(props: IGoogleMapsProps) {
    const jsApiLoader = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.VITE_GMAPS_APIKEY ?? '',
    })

    const [, setMapRef] = React.useState<google.maps.Map | null>(null)

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        map.setZoom(props.zoom || DEFAULT_ZOOM_VALUE)
        setMapRef(map)
    }, [])

    const onUnmount = React.useCallback(function callback() {
        setMapRef(null)
    }, [])

    if (!jsApiLoader.isLoaded) {
        return 'map is loading...'
    }

    return (
        <GoogleMap
            options={{
                rotateControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                styles: mapTheme,
            }}
            mapContainerStyle={props.containerStyle ?? containerStyle}
            center={props.center ?? { lat: 7.3669401, lng: 3.8665949 }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={props.onClick}
            zoom={props.zoom || DEFAULT_ZOOM_VALUE}
        >
            {(props.checkins || []).map((checkin, index) => (
                <CheckinMarker
                    status={checkin.status}
                    index={index+1}
                    cords={{
                        lat: parseFloat(checkin.latitude),
                        lng: parseFloat(checkin.longitude),
                        /* lat: checkin.latitude,
                        lng: checkin.longitude, */
                    }}
                    onClick={() => {
                        //navigate(`/checkins/${checkin.id}`)
                    }}
                    key={checkin.id}
                    isSelected={props.monitoring}
                />
            ))}
            {props.clickedCoords && (
                <Marker
                    position={{
                        lat: props.clickedCoords.lat,
                        lng: props.clickedCoords.lng,
                    }}
                    onClick={() => {
                        // Handle marker click
                    }}
                />
            )}
        </GoogleMap>
    )
}
