import { extendTheme } from '@mui/joy/styles'

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50:  '#a4b3eb',
                    100: '#8a9ad8',
                    200: '#6c7ec5',
                    300: '#5366b0   ',
                    400: '#3e5198',
                    500: '#273c89',
                    600: '#172b74',
                    700: '#0d2169',
                    800: '#071a5e',
                    900: '#04144E'
                },
                
                danger: {
                    50: '#FFEBEE',
                    100: '#FFCDD2',
                    200: '#EF9A9A',
                    300: '#E57373',
                    400: '#EF5350',
                    500: '#F44336',
                    600: '#E53935',
                    700: '#D32F2F',
                    800: '#C62828',
                    900: '#B71C1C',
                },
                /* */
            },
        },
    },
    fontFamily: {
        display: 'Inter, var(--joy-fontFamily-fallback)',
        body: 'Inter, var(--joy-fontFamily-fallback)',
    },
    typography: {
        h4: {
            '@media (max-width:600px)': {
                fontSize: '1rem',
            },
        },
    },
})

export { theme }
