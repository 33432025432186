import { CssVarsProvider } from '@mui/joy/styles';
import { Navigate, Outlet } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Sidebar from '@/src/pages/secure/secure-layout/components/Sidebar';
import { colors } from '@/utilities/colors';
import { useAuthUserController } from '@/services/use-get-auth-user';
import { Loading } from '@/components/loading-screen';
export function SecureLayout() {
    const authUserController = useAuthUserController()
    if (authUserController.isPending) {
        return <Loading text='Please wait ...' description='Fetching data from server ...' />   
    }

    if (!authUserController.isAuth && !authUserController.user) {
        return <Navigate to={'/login'} />
    }

    if (authUserController.user && !authUserController.user.emailVerified) {
        return <Navigate to={'/change-password'} />
    }


    return (
        <CssVarsProvider disableTransitionOnChange>

            <Box sx={{ display: 'flex', height: '100vh', backgroundColor: colors.white }}>
                <Box sx={{
                    width: '20vw',
                    height: '100vh',
                    color: 'white',
                    position: 'fixed',
                }}>
                    <Sidebar />
                </Box>
                <Box sx={{ marginLeft: '20vw', width: '78vw' }}>
                   <Outlet />
                </Box>
            </Box>
        </CssVarsProvider>
    );
}