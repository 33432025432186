import { Typography, Box } from '@mui/joy'
import { motion } from 'framer-motion'
import { colors } from '@/utilities/colors.ts'

const ballStyle = {
    display: 'block',
    width: '1rem',
    height: '1rem',
    backgroundColor: 'black',
    borderRadius: '0.5rem',
    marginBottom: 20,
}

const bounceTransition = {
    y: {
        duration: 1.5,
        repeat: Infinity,
        ease: 'easeOut',
    },
    backgroundColor: {
        duration: 1.5,
        repeat: Infinity,
        ease: 'easeOut',
    },
}
export function Loading(props: { text?: string, description?: string }) {
    

    return (
        <Box
            sx={{
                display: 'flex',
                width: '60vw',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: colors.white
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '60vw',
                }}
            >
                <motion.span
                    style={ballStyle}
                    transition={bounceTransition}
                    animate={{
                        y: ['100%', '-100%'],
                        backgroundColor: [colors.secondary, colors.primary],
                    }}
                />
                <Typography level={'h3'}>
                    {props.text || 'Loading...'}.
                </Typography>
                <Typography>{props.description || 'We are consulting the oracles.'}</Typography>
            </Box>
        </Box>
    )
}
